import { Add } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { CurrencySource, defaultCurrencyCode } from 'utilities/currencyUtils';
import { C4ReconciliationsDateRangePicker } from '../_components/c4ReconciliationsDateRangePicker';
import { NotesField } from '../_components/notesField';
import { ProjectedTransactionsGrid } from '../_components/projectedTransactionsGrid';
import { ReconciliationTab } from '../_components/reconciliationDrawer';
import { ReportedTransactionsGrid } from '../_components/reportedTransactionsGrid';
import { SelectedTotalAlert } from '../_components/selectedTotal';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import { UnreconciledAmountAlert } from '../_components/unreconciledAmount';

export type ModifyReconciliationViewProps = {
	selectedProjectedIds: string[];
	selectedReportedIds: string[];
	setSelectedProjectedIds: (ids: string[]) => void;
	setSelectedReportedIds: (ids: string[]) => void;
};

export const ModifyReconciliationView: FC<ModifyReconciliationViewProps> =
	observer(
		({
			selectedProjectedIds,
			selectedReportedIds,
			setSelectedProjectedIds,
			setSelectedReportedIds,
		}) => {
			const {
				projectedTransactionsQueryContext: {
					loading: projectedTransactionsLoading,
					data: projectedTransactions,
					refetch: refetchProjectedTransactions,
				},
				reportedTransactionsQueryContext: {
					isLoading: reportedTransactionsLoading,
					data: reportedTransactions,
					refetch: reloadReportedTransactions,
				},
				getSingleReconciliationQuery: { isFetching: reconciliationIsFetching },
				tab,
				reconciliation,
				setProjectedTransactionDrawerOpen,
				open: reconciliationDrawerOpen,
			} = useReconciliationsContext();

			useEffect(() => {
				if (reconciliationDrawerOpen) {
					refetchProjectedTransactions();
					reloadReportedTransactions();
				}
			}, [
				reconciliationDrawerOpen,
				refetchProjectedTransactions,
				reloadReportedTransactions,
			]);

			const projectedTransactionsActual = useMemo(() => {
				if (reconciliation) {
					return [
						...reconciliation.projectedTransactions,
						...projectedTransactions,
					];
				} else {
					return projectedTransactions;
				}
			}, [projectedTransactions, reconciliation]);

			const reportedTransactionsActual = useMemo(() => {
				if (reconciliation) {
					return [
						...reconciliation.reportedTransactions,
						...(reportedTransactions ?? []),
					];
				} else {
					return reportedTransactions ?? [];
				}
			}, [reconciliation, reportedTransactions]);

			const selectedProjectedTransactions = useMemo(() => {
				return projectedTransactionsActual.filter((item) =>
					selectedProjectedIds.includes(item.id),
				);
			}, [selectedProjectedIds, projectedTransactionsActual]);

			const selectedReportedTransactions = useMemo(() => {
				return reportedTransactionsActual?.filter((item) =>
					selectedReportedIds.includes(item.id),
				);
			}, [selectedReportedIds, reportedTransactionsActual]);

			const [
				reportedCurrencySource,
				reportedCurrencyCode,
				reportedReportingCurrencyCode,
			] = useMemo(() => {
				let source = CurrencySource.Account;
				let accountCurrency = defaultCurrencyCode;
				let reportingCurrency = defaultCurrencyCode;
				const codes = new Set<string>();
				selectedReportedTransactions.forEach((rt) => {
					codes.add(rt.number.accountCurrencyCode);
					if (
						rt.number.accountCurrencyCode &&
						rt.number.accountCurrencyCode !== accountCurrency
					) {
						accountCurrency = rt.number.accountCurrencyCode;
					}
					if (
						rt.number.reportingCurrencyCode &&
						rt.number.reportingCurrencyCode !== reportingCurrency
					) {
						reportingCurrency = rt.number.reportingCurrencyCode;
					}
				});
				if (codes.size >= 2) {
					source = CurrencySource.Reporting;
				}
				return [
					source,
					source === CurrencySource.Account
						? accountCurrency
						: reportingCurrency,
					reportingCurrency,
				];
			}, [selectedReportedTransactions]);

			const [projectedCurrencySource, projectedCurrencyCode] = useMemo(() => {
				let source = CurrencySource.Account;
				let accountCurrency = defaultCurrencyCode;
				let reportingCurrency = defaultCurrencyCode;
				const codes = new Set<string>();
				selectedProjectedTransactions.forEach((rt) => {
					codes.add(rt.amount.accountCurrencyCode);
					if (
						rt.amount.accountCurrencyCode &&
						rt.amount.accountCurrencyCode !== accountCurrency
					) {
						accountCurrency = rt.amount.accountCurrencyCode;
					}
					if (
						rt.amount.reportingCurrencyCode &&
						rt.amount.reportingCurrencyCode !== reportingCurrency
					) {
						reportingCurrency = rt.amount.reportingCurrencyCode;
					}
				});
				if (codes.size >= 2) {
					source = CurrencySource.Reporting;
				}
				return [
					source,
					source === CurrencySource.Account
						? accountCurrency
						: reportingCurrency,
				];
			}, [selectedProjectedTransactions]);

			const [displayedCurrencySource, displayedCurrencyCode] = useMemo(() => {
				const source =
					reportedCurrencySource === projectedCurrencySource &&
					reportedCurrencyCode === projectedCurrencyCode
						? reportedCurrencySource
						: CurrencySource.Reporting;

				const code =
					reportedCurrencySource === projectedCurrencySource &&
					reportedCurrencyCode === projectedCurrencyCode
						? reportedCurrencyCode
						: reportedReportingCurrencyCode;

				return [source, code];
			}, [
				reportedCurrencySource,
				reportedCurrencyCode,
				projectedCurrencySource,
				projectedCurrencyCode,
				reportedReportingCurrencyCode,
			]);

			const projectedAmount = useMemo(() => {
				let result = 0;
				selectedProjectedTransactions.forEach((rt) => {
					const reportingAmount =
						rt.amount.reportingCurrencyAmount !== undefined
							? rt.amount.reportingCurrencyAmount
							: rt.amount.accountCurrencyAmount === 0
							? 0
							: NaN;
					result +=
						displayedCurrencySource === CurrencySource.Reporting
							? reportingAmount
							: rt.amount.accountCurrencyAmount;
				});
				return result;
			}, [selectedProjectedTransactions, displayedCurrencySource]);

			const isProjectedAmountValid = useMemo(() => {
				return !isNaN(projectedAmount);
			}, [projectedAmount]);

			const reportedAmount = useMemo(() => {
				let result = 0;
				selectedReportedTransactions.forEach((rt) => {
					const reportingAmount =
						rt.number.reportingCurrencyAmount !== undefined
							? rt.number.reportingCurrencyAmount
							: rt.number.accountCurrencyAmount === 0
							? 0
							: NaN;
					result +=
						displayedCurrencySource === CurrencySource.Reporting
							? reportingAmount
							: rt.number.accountCurrencyAmount;
				});
				return result;
			}, [selectedReportedTransactions, displayedCurrencySource]);

			const isReportedAmountValid = useMemo(() => {
				return !isNaN(reportedAmount);
			}, [reportedAmount]);

			const unreconciledAmount = useMemo(() => {
				return projectedAmount - reportedAmount;
			}, [projectedAmount, reportedAmount]);

			const isUnreconciledAmountValid = useMemo(() => {
				return !isNaN(unreconciledAmount);
			}, [unreconciledAmount]);

			const unreconciledVariance = useMemo(() => {
				if (unreconciledAmount === 0) {
					return 0;
				} else if (projectedAmount === 0) {
					return 1;
				} else {
					return unreconciledAmount / projectedAmount;
				}
			}, [unreconciledAmount, projectedAmount]);

			const loadingGrid = useMemo(() => {
				return (
					<Grid
						sx={{
							height: 'inherit',
							width: 'inherit',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress />
					</Grid>
				);
			}, []);

			const step1Projected = useMemo(() => {
				if (projectedTransactionsLoading) {
					return loadingGrid;
				} else
					return (
						<>
							<Grid item container xs={true}>
								<ActuallyPrettyGoodDataGridWrapper>
									<ProjectedTransactionsGrid
										stonlyId="projected-items-selection"
										tableKey="projected-items-selection"
										projectedTransactions={projectedTransactionsActual}
										selectedProjectedIds={selectedProjectedIds}
										setSelectedProjectedIds={setSelectedProjectedIds}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Grid>
							<Grid item xs="auto">
								<SelectedTotalAlert
									amount={projectedAmount}
									currencyCode={displayedCurrencyCode}
									currencySource={displayedCurrencySource}
									isAmountValid={isProjectedAmountValid}
								/>
							</Grid>
						</>
					);
			}, [
				displayedCurrencyCode,
				displayedCurrencySource,
				isProjectedAmountValid,
				loadingGrid,
				projectedAmount,
				projectedTransactionsActual,
				projectedTransactionsLoading,
				selectedProjectedIds,
				setSelectedProjectedIds,
			]);
			const step1Reported = useMemo(() => {
				if (reportedTransactionsLoading || reconciliationIsFetching) {
					return loadingGrid;
				} else {
					return (
						<>
							<Grid item xs={true}>
								<ActuallyPrettyGoodDataGridWrapper>
									<ReportedTransactionsGrid
										stonlyId="reported-items-selection"
										tableKey="reoprted-items-selection"
										reportedTransactions={reportedTransactionsActual}
										selectedReportedIds={selectedReportedIds}
										setSelectedReportedIds={setSelectedReportedIds}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Grid>
							<Grid item xs={12}>
								<SelectedTotalAlert
									amount={reportedAmount}
									currencyCode={displayedCurrencyCode}
									currencySource={displayedCurrencySource}
									isAmountValid={isReportedAmountValid}
								/>
							</Grid>
						</>
					);
				}
			}, [
				displayedCurrencyCode,
				displayedCurrencySource,
				isReportedAmountValid,
				loadingGrid,
				reconciliationIsFetching,
				reportedAmount,
				reportedTransactionsActual,
				reportedTransactionsLoading,
				selectedReportedIds,
				setSelectedReportedIds,
			]);
			const step2Reported = useMemo(() => {
				if (reportedTransactionsLoading || reconciliationIsFetching) {
					return loadingGrid;
				} else {
					return (
						<>
							<Grid item xs={true}>
								<ActuallyPrettyGoodDataGridWrapper>
									<ReportedTransactionsGrid
										stonlyId="reported-items-selected"
										tableKey="reoprted-items-selected"
										reportedTransactions={selectedReportedTransactions}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Grid>
							<Grid item xs="auto">
								<SelectedTotalAlert
									amount={reportedAmount}
									currencyCode={displayedCurrencyCode}
									currencySource={displayedCurrencySource}
									isAmountValid={isReportedAmountValid}
								/>
							</Grid>
						</>
					);
				}
			}, [
				displayedCurrencyCode,
				displayedCurrencySource,
				isReportedAmountValid,
				loadingGrid,
				reconciliationIsFetching,
				reportedAmount,
				reportedTransactionsLoading,
				selectedReportedTransactions,
			]);
			const step2Projected = useMemo(() => {
				if (projectedTransactionsLoading || reconciliationIsFetching) {
					return loadingGrid;
				} else {
					return (
						<>
							<Grid item xs={true}>
								<ActuallyPrettyGoodDataGridWrapper>
									<ProjectedTransactionsGrid
										stonlyId="projected-items-selected"
										tableKey="projected-items-selected"
										projectedTransactions={selectedProjectedTransactions}
									/>
								</ActuallyPrettyGoodDataGridWrapper>
							</Grid>
							<Grid item xs="auto">
								<SelectedTotalAlert
									amount={projectedAmount}
									currencyCode={displayedCurrencyCode}
									currencySource={displayedCurrencySource}
									isAmountValid={isProjectedAmountValid}
								/>
							</Grid>
						</>
					);
				}
			}, [
				displayedCurrencyCode,
				displayedCurrencySource,
				isProjectedAmountValid,
				loadingGrid,
				projectedAmount,
				projectedTransactionsLoading,
				reconciliationIsFetching,
				selectedProjectedTransactions,
			]);

			const reconciliationInfo = useMemo(() => {
				if (
					reconciliationIsFetching ||
					reportedTransactionsLoading ||
					projectedTransactionsLoading
				) {
					return loadingGrid;
				}

				return (
					<>
						<Grid item xs={12}>
							<UnreconciledAmountAlert
								amount={unreconciledAmount}
								currencyCode={displayedCurrencyCode}
								currencySource={displayedCurrencySource}
								isAmountValid={isUnreconciledAmountValid}
								variance={unreconciledVariance}
							/>
						</Grid>
						;
					</>
				);
			}, [
				displayedCurrencyCode,
				displayedCurrencySource,
				isUnreconciledAmountValid,
				loadingGrid,
				projectedTransactionsLoading,
				reconciliationIsFetching,
				reportedTransactionsLoading,
				unreconciledAmount,
				unreconciledVariance,
			]);

			return (
				<TabContext value={tab.toString()}>
					{/* Step 1 of reconciliations */}
					<TabPanel
						value={ReconciliationTab.Selection.toString()}
						sx={{ height: '100%', padding: 0 }}
					>
						<Grid container item xs={12} spacing={1}>
							{/* Reported Transactions Grid */}
							<Grid
								container
								item
								lg={6}
								md={12}
								sx={{
									height: '100%',
									gap: 2,
									flexDirection: 'column',
									flexWrap: 'nowrap',
									minHeight: 500,
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{
										minHeight: '48px',
										justifyContent: 'space-between',
									}}
								>
									<Grid container item xs="auto">
										<Typography variant="h4" sx={{ alignSelf: 'flex-end' }}>
											Reported
										</Typography>
									</Grid>
									<Grid item xs="auto">
										<C4ReconciliationsDateRangePicker />
									</Grid>
								</Grid>
								{step1Reported}
							</Grid>

							{/* Projected Transactions Grid */}
							<Grid
								container
								item
								lg={6}
								md={12}
								sx={{
									height: '100%',
									gap: 2,
									flexDirection: 'column',
									flexWrap: 'nowrap',
									minHeight: 500,
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{
										minHeight: '48px',
										justifyContent: 'space-between',
									}}
								>
									<Grid container item xs="auto">
										<Typography variant="h4" sx={{ alignSelf: 'flex-end' }}>
											Projected
										</Typography>
									</Grid>
									<Grid container item xs="auto">
										<Button
											variant="outlined"
											startIcon={<Add />}
											onClick={() => setProjectedTransactionDrawerOpen(true)}
											sx={{ alignSelf: 'flex-end' }}
										>
											Projections
										</Button>
									</Grid>
								</Grid>
								{step1Projected}
							</Grid>
							{reconciliationInfo}
						</Grid>
					</TabPanel>

					{/* Step 2 of reconciliations */}
					<TabPanel
						value={ReconciliationTab.Detail.toString()}
						sx={{ height: '100%', width: '100%', padding: 0 }}
					>
						<Grid container item xs={12} spacing={1}>
							{/* Reported Transactions Grid */}
							<Grid
								container
								item
								lg={6}
								md={12}
								sx={{
									height: '100%',
									gap: 2,
									flexDirection: 'column',
									flexWrap: 'nowrap',
									minHeight: 460,
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{
										minHeight: '48px',
										justifyContent: 'space-between',
									}}
								>
									<Grid item container xs="auto">
										<Typography variant="h4" sx={{ alignSelf: 'flex-end' }}>
											Reported
										</Typography>
									</Grid>
								</Grid>
								{step2Reported}
							</Grid>

							{/* Projected Transactions Grid */}
							<Grid
								container
								item
								lg={6}
								md={12}
								sx={{
									height: '100%',
									gap: 2,
									flexDirection: 'column',
									flexWrap: 'nowrap',
									minHeight: 460,
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{
										minHeight: '48px',
										justifyContent: 'space-between',
									}}
								>
									<Grid container item xs="auto">
										<Typography variant="h4" sx={{ alignSelf: 'flex-end' }}>
											Projected
										</Typography>
									</Grid>
									<Grid container item xs="auto">
										<Button
											variant="outlined"
											startIcon={<Add />}
											onClick={() => setProjectedTransactionDrawerOpen(true)}
											sx={{ alignSelf: 'flex-end' }}
										>
											Projection
										</Button>
									</Grid>
								</Grid>
								{step2Projected}
							</Grid>
							{/* Notes */}
							{reconciliationInfo}
							<Grid item xs={12}>
								<NotesField />
							</Grid>
						</Grid>
					</TabPanel>
				</TabContext>
			);
		},
	);
