import { DateRange } from '@mui/x-date-pickers-pro';
import {
	useProjectedTransactionsQuery,
	UseProjectedTransactionsQueryProps,
} from '../../_queries/useProjectedTransactionsQuery';
import {
	useReconciliationsQuery,
	UseReconciliationsQueryProps,
} from 'features/cash4/_queries/useReconciliationsQuery';
import { observer } from 'mobx-react-lite';
import {
	ProjectedTransaction,
	Reconciliation,
} from 'modules/clients/customer-api/src/api/cash4';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import {
	ReconciliationMode,
	ReconciliationTab,
} from '../_components/reconciliationDrawer';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TransactionListItem } from '../../transactions/models';

//#region Context

type ReconciliationsContextProps = {
	open: boolean;
	mode: ReconciliationMode;
	tab: ReconciliationTab;
	reconciliationQueryContext: UseReconciliationsQueryProps;
	projectedTransactionsQueryContext: UseProjectedTransactionsQueryProps;
	reportedTransactionsQueryContext: UseQueryResult<
		TransactionListItem[] | undefined
	>;
	getSingleReconciliationQuery: UseQueryResult<Reconciliation | undefined>;
	reconciliation: Reconciliation | undefined;
	reconciliationId: string | undefined;
	setReconciliationId: (id: string) => void;
	dateRange: DateRange<Moment> | undefined;
	notes: string | undefined;
	projectedTransactionDrawerOpen: boolean;
	selectedProjectedTransaction: ProjectedTransaction | undefined;
	selectedTransaction: TransactionListItem | undefined;
	setProjectedTransactionDrawerOpen: (open: boolean) => void;
	setSelectedProjectedTransaction: (
		item: ProjectedTransaction | undefined,
	) => void;
	setSelectedTransaction: (
		transaction: TransactionListItem | undefined,
	) => void;
	setOpen: (open: boolean) => void;
	setMode: (mode: ReconciliationMode) => void;
	setTab: (tab: ReconciliationTab) => void;
	setDateRange: (dateRange: DateRange<Moment> | undefined) => void;
	setNotes: (notes: string | undefined) => void;
	startReconciliationCreation: () => void;
	updateReconciliation: (
		projectedTransactions: string[],
		reportedTransactions: string[],
	) => Promise<void>;
	viewReconciliation: (reconciliation: string | Reconciliation) => void;
	editReconciliation: (reconciliation: string | Reconciliation) => void;
	deleteReconciliation: (reconciliation: Reconciliation) => void;
	onDrawerClose: () => void;
};

const ReconciliationsContext = createContext<ReconciliationsContextProps>(
	{} as ReconciliationsContextProps,
);

//#endregion

//#region Provider

export type CreateReconciliationTab = 'selection' | 'details';

export type ReconciliationCreateRecord = {
	reportedTransactions: string[];
	projectedTransactions: string[];
	note?: string;
};

export type ReconciliationsProviderProps = {
	children: ReactNode;
};

export const ReconciliationsProvider: FC<ReconciliationsProviderProps> =
	observer(({ children }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();

		//#region Data

		const [dateRange, setDateRange] = useState<DateRange<Moment> | undefined>([
			moment().subtract(1, 'day'),
			moment(),
		]);

		const reconciliationsQueryContext = useReconciliationsQuery();
		const projectedTransactionsQueryContext = useProjectedTransactionsQuery({
			reconciliationStatuses: ['Unreconciled'],
		});

		const reportedTransactionsQueryContext = useQuery(
			['reportedTransactions', dateRange],
			() =>
				customerApiClient.transactions
					.all({
						startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
						endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
						reconciliationStatuses: ['Posted'],
					})
					.then((response) => response?.data?.data || []),
			{
				enabled: !!dateRange && !!dateRange[0] && !!dateRange[1],
				refetchOnWindowFocus: false,
				refetchInterval: 0,
			},
		);
		//#endregion

		//#region State

		const [open, setOpen] = useState(false);
		const [mode, setMode] = useState<ReconciliationMode>(
			ReconciliationMode.Create,
		);
		const [reconciliation, setReconciliation] = useState<Reconciliation>();
		const [reconciliationId, setReconciliationId] = useState<string>();
		const [tab, setTab] = useState<ReconciliationTab>(
			ReconciliationTab.Selection,
		);
		const [notes, setNotes] = useState<string>();

		const [projectedTransactionDrawerOpen, setProjectedTransactionDrawerOpen] =
			useState(false);
		const [selectedProjectedTransaction, setSelectedProjectedTransaction] =
			useState<ProjectedTransaction>();
		const [selectedTransaction, setSelectedTransaction] =
			useState<TransactionListItem>();

		const onDrawerClose = useCallback(() => {
			setOpen(false);
			setReconciliation(undefined);
			setReconciliationId(undefined);
			setDateRange([moment().subtract(1, 'day'), moment()]);
			setNotes(undefined);
			setTab(ReconciliationTab.Selection);
		}, []);

		const startReconciliationCreation = useCallback(() => {
			setMode(ReconciliationMode.Create);
			setTab(ReconciliationTab.Selection);
			setOpen(true);
		}, []);

		const getSingleReconciliationQuery = useQuery(
			['reconciliation', reconciliationId],
			() => {
				return customerApiClient.api.cash4
					.reconciliation(reconciliationId!)
					.then((res) => res?.data?.data);
			},
			{
				refetchOnWindowFocus: false,
				enabled: !!reconciliationId,
			},
		);

		const { data: reconciliationData, isFetching: isReconcilationFetching } = getSingleReconciliationQuery;

		useEffect(() => {
			if (reconciliationData) {
				setReconciliation(reconciliationData);
			} else {
				if (open && !isReconcilationFetching && reconciliationId) {
					enqueueSnackbar(
						'An error occurred while retrieving the reconciliation.',
						{
							variant: 'error',
						},
					);

					onDrawerClose();
				}
			}
		}, [enqueueSnackbar, isReconcilationFetching, onDrawerClose, open, reconciliationData]);

		const viewReconciliation = useCallback(
			async (reconciliation: string | Reconciliation) => {
				if (typeof reconciliation === 'string') {
					try {
						setReconciliationId(reconciliation);

						setMode(ReconciliationMode.View);
						setOpen(true);
					} catch {
						enqueueSnackbar(
							'An error occurred while retrieving the reconciliation.',
							{
								variant: 'error',
							},
						);
					}
				} else {
					setReconciliation(reconciliation);
					setMode(ReconciliationMode.View);
					setOpen(true);
				}
			},
			[enqueueSnackbar],
		);

		const editReconciliation = useCallback(
			async (reconciliation: string | Reconciliation) => {
				if (typeof reconciliation === 'string') {
					setReconciliationId(reconciliation);
					try {
						setMode(ReconciliationMode.Edit);
						setTab(ReconciliationTab.Selection);
						setOpen(true);
					} catch {
						enqueueSnackbar(
							'An error occurred while retrieving the reconciliation.',
							{
								variant: 'error',
							},
						);
					}
				} else {
					setReconciliation(reconciliation);
					setNotes(reconciliation.notes);
					setMode(ReconciliationMode.Edit);
					setTab(ReconciliationTab.Selection);
					setOpen(true);
				}
			},
			[enqueueSnackbar],
		);

		const updateReconciliation = useCallback(
			async (projectedIds: string[], reportedIds: string[]) => {
				if (
					reconciliation &&
					projectedIds &&
					projectedIds.length > 0 &&
					reportedIds &&
					reportedIds.length > 0
				) {
					const response =
						await customerApiClient.api.cash4.updateReconciliation(
							reconciliation.id,
							{
								projectedTransactions: projectedIds,
								reportedTransactions: reportedIds,
								note: (notes?.trim()?.length ?? 0) > 0 ? notes : undefined,
							},
						);

					if (response.data?.data) {
						reconciliationsQueryContext.refetch();
						projectedTransactionsQueryContext.refetch();
						reportedTransactionsQueryContext.refetch();
						onDrawerClose();
					} else {
						throw new Error('Unable to update reconciliation.');
					}
				} else {
					enqueueSnackbar(
						'An error occurred and the reconciliation could not be updated.',
						{
							variant: 'error',
						},
					);
				}
			},
			[
				customerApiClient.api.cash4,
				enqueueSnackbar,
				notes,
				onDrawerClose,
				projectedTransactionsQueryContext,
				reconciliation,
				reconciliationsQueryContext,
				reportedTransactionsQueryContext,
			],
		);

		const deleteReconciliation = useCallback(
			async (reconciliation: Reconciliation) => {
				if (reconciliation) {
					const response =
						await customerApiClient.api.cash4.deleteReconciliation(
							reconciliation.id,
						);

					if (response.data?.data) {
						reconciliationsQueryContext.refetch();
						enqueueSnackbar('The reconciliation was successfully deleted.', {
							variant: 'success',
						});
					} else {
						throw new Error('Unable to delete reconciliation.');
					}
				} else {
					enqueueSnackbar(
						'An error occurred and the reconciliation could not be deleted.',
						{
							variant: 'error',
						},
					);
				}
			},
			[
				customerApiClient.api.cash4,
				enqueueSnackbar,
				reconciliationsQueryContext,
			],
		);

		//#endregion

		return (
			<ReconciliationsContext.Provider
				value={{
					open: open,
					mode: mode,
					tab: tab,
					reconciliationQueryContext: reconciliationsQueryContext,
					projectedTransactionsQueryContext: projectedTransactionsQueryContext,
					reportedTransactionsQueryContext: reportedTransactionsQueryContext,
					reconciliation: reconciliation,
					reconciliationId: reconciliationId,
					setReconciliationId: setReconciliationId,
					getSingleReconciliationQuery: getSingleReconciliationQuery,
					dateRange: dateRange,
					notes: notes,
					projectedTransactionDrawerOpen: projectedTransactionDrawerOpen,
					selectedProjectedTransaction: selectedProjectedTransaction,
					selectedTransaction: selectedTransaction,
					setProjectedTransactionDrawerOpen: setProjectedTransactionDrawerOpen,
					setSelectedProjectedTransaction: setSelectedProjectedTransaction,
					setSelectedTransaction: setSelectedTransaction,
					setOpen: setOpen,
					setMode: setMode,
					setTab: setTab,
					setDateRange: setDateRange,
					setNotes: setNotes,
					startReconciliationCreation: startReconciliationCreation,
					updateReconciliation: updateReconciliation,
					viewReconciliation: viewReconciliation,
					editReconciliation: editReconciliation,
					deleteReconciliation: deleteReconciliation,
					onDrawerClose: onDrawerClose,
				}}
			>
				{children}
			</ReconciliationsContext.Provider>
		);
	});

//#endregion

//#region Hook

export type UseReconciliationsContextProps = ReconciliationsContextProps;

export function useReconciliationsContext(): UseReconciliationsContextProps {
	return useContext(ReconciliationsContext);
}

//#endregion
